<!--
 * @Author: 候怀烨
 * @Date: 2021-03-04 16:01:04
 * @LastEditTime: 2021-03-04 17:29:49
 * @LastEditors: Please set LastEditors
 * @Description: 资产负债表
 * @FilePath: \sd-vue-admin\src\views\project\finance\list\balanceSheet\index.vue
-->
<!-- NAME[epic=财务] 资产负债表 -->
<template>
  <div class="orderTest-container">
    <el-form inline :model="form">
      <el-form-item prop="year">
        <el-date-picker
          v-model="form.year"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月"
          style="width: 180px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlerInquire">查 询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导 出</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-col :span="12">
        <el-table
          stripe
          :data="tableData.money"
          row-key="id"
          border
          default-expand-all
          :tree-props="{ children: 'children' }"
        >
          <el-table-column
            label="资产"
            align="left"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="行次"
            align="right"
            prop="level"
          ></el-table-column>
          <el-table-column
            label="期末余额"
            align="right"
            prop="end_money"
          ></el-table-column>
          <el-table-column
            label="年初余额"
            align="right"
            prop="begin_money"
          ></el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-table
          stripe
          :data="tableData.debt"
          row-key="id"
          border
          default-expand-all
          :tree-props="{ children: 'children' }"
        >
          <el-table-column
            label="负债"
            align="left"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="行次"
            align="right"
            prop="level"
          ></el-table-column>
          <el-table-column
            label="期末余额"
            align="right"
            prop="end_money"
          ></el-table-column>
          <el-table-column
            label="年初余额"
            align="right"
            prop="begin_money"
          ></el-table-column>
        </el-table>
        <el-table
          stripe
          :data="tableData.rights"
          row-key="id"
          border
          default-expand-all
          :tree-props="{ children: 'children' }"
        >
          <el-table-column
            label="所有者权益"
            align="left"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="行次"
            align="right"
            prop="level"
          ></el-table-column>
          <el-table-column
            label="期末余额"
            align="right"
            prop="end_money"
          ></el-table-column>
          <el-table-column
            label="年初余额"
            align="right"
            prop="begin_money"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        form: {
          year: '',
        },
        url: {
          list: '/cashAdmin/subject-detail/debt',
        },
        tableData: [],
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlerInquire() {
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res, '')
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleExport() {
        downloadFile(
          '/cashAdmin/subject-detail/export-debt',
          '资产负债表.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
